<template>
	<table class="offer-table">
		<thead class="offer-table__head">
			<tr>
				<th>{{ $t('web.unit') }} N</th>
				<th>{{ $t('web.rooms') }}</th>
				<th>{{ $t('web.size') }}</th>
				<th>{{ $t('web.price') }}</th>
				<th>{{ $t('web.flatNumber') }}</th>
				<th>{{ $t('web.floor') }}</th>
				<th>{{ $t('web.view') }}</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="item in 5" :key="item">
				<td class="offer-table__item offer-table__item-number"># 16-507</td>
				<td class="offer-table__item">4 BR</td>
				<td class="offer-table__item">3 297 ft2</td>
				<td class="offer-table__item offer-table__item-price">
					<span>2 240 756,7</span> USD
				</td>
				<td class="offer-table__item">108</td>
				<td class="offer-table__item">4</td>
				<td class="offer-table__item">Community</td>
			</tr>
		</tbody>
	</table>
</template>
<script>
export default {
	name: 'OfferTable'
}
</script>
<style lang="scss">
.offer-table {
	border-spacing: 0 5px;
	max-width: 670px;
	width: 100%;
	&__head th {
		font-size: 12px;
		color: var(--gray-50, #808080);
		line-height: normal;
		cursor: pointer;
		padding: 9px 5px;
		font-weight: 400;
	}
	&__item {
		font-size: 12px;
		font-weight: 400;
		line-height: normal;
		text-align: center;
		padding: 8px 5px;
		border-top: 1px solid var(--color-stroke, #e5e5e5);
		border-bottom: 1px solid var(--color-stroke, #e5e5e5);
		min-width: 70px;
		&:first-child {
			border-left: 1px solid var(--color-stroke, #e5e5e5);
			border-radius: 4px 0 0 4px;
			padding-left: 20px;
		}
		&:last-child {
			border-right: 1px solid var(--color-stroke, #e5e5e5);
			border-radius: 0 4px 4px 0;
			padding-right: 20px;
		}
		&-number {
			font-size: 14px;
			font-weight: 700;
		}
		&-price {
			white-space: nowrap;
			span {
				display: unset;
				color: var(--primary-1, #c99d56);
			}
		}
	}
	&__add {
		i {
			font-size: 28px;
			display: block;
			text-align: end;
			color: #c99d56;
		}
		p {
			white-space: nowrap;
			margin: 5px 0 0 0;
			font-size: 12px;
			line-height: normal;
			text-align: end;
			color: var(--secondary-1, #808080);
		}

		&.added {
			i {
				fill: #c99d56;
			}

			span {
				color: var(--text-1, #000);
				font-weight: 700;
			}
		}
	}
	@media screen and (max-width: 620px) {
		display: none;
	}
}
</style>
