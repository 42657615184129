<template>
	<div class="agent-info">
		<div class="agent-info__left">
			<!-- <div class="agent-info__top">{{ $t('web.your_agent') }}</div> -->
			<div class="agent-info__main">
				<or-image
					:src="content.photo"
					width="180"
					height="180"
					:alt="content.name"
				/>
				<div class="agent-info__info">
					<div class="agent-info__name">{{ content.name_offer }}</div>
					<div
						v-if="content.languages"
						class="agent-info__info-item agent-info__info-item--langs"
					>
						<span>{{ $t('web.languages') }}</span>
						<p>{{ content.languages?.split(',').join(', ') }}</p>
					</div>
					<div class="agent-info__info-item agent-info__info-item--link">
						<span>{{ $t('web.phone') }}</span>
						<a :href="`tel:+${content.phone}`" target="_blank">{{ phone }}</a>
					</div>
					<div class="agent-info__info-item text">
						<p>
							{{ content.description }}
						</p>
					</div>
				</div>
			</div>
			<p class="agent-info__left-text">
				{{ content.description }}
			</p>
			<div class="agent-info__socials">
				<template v-for="item in socials" :key="item">
					<a v-if="item.link" :href="item.link" target="_blank">
						<img
							:src="require(`@/assets/img/svg/agent-info/${item.icon}.svg`)"
							:alt="item.icon"
							decoding="async"
						/>
					</a>
				</template>
			</div>
			<div
				v-for="link in content.links"
				:key="link.name"
				class="agent-info__link"
			>
				<span>{{ link.name }}</span>
				<a :href="link.url" target="_blank">{{ link.url }}</a>
			</div>
		</div>
		<div v-if="content.agency" class="agent-info__right">
			<div class="agent-info__top">{{ $t('web.company') }}</div>
			<div class="agent-info__main">
				<or-image
					:src="content.agency.logo"
					width="180"
					height="180"
					:alt="content.agency.name"
				/>
				<div class="agent-info__right-info">
					<div class="agent-info__info-item">
						<span>{{ $t('web.company_name') }}</span>
						<p>{{ content.agency.name }}</p>
					</div>
					<div class="agent-info__info-item agent-info__info-item--link">
						<span>{{ $t('web.website') }}</span>
						<a :href="content.agency.website" target="_blank">
							{{ content.agency.website }}
						</a>
					</div>
					<div class="agent-info__info-item">
						<span>{{ $t('web.position_in_agency') }}</span>
						<p>{{ content.position }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import WhatsApp from '@/components/svg/Solo/WhatsApp.vue'
import formatPhone from '@/utils/formatPhone'

export default {
	name: 'AgentInfo',
	components: { WhatsApp },
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		phone() {
			return this.content.phone ? formatPhone(this.content.phone) : ''
		},
		socials() {
			return [
				{
					icon: 'whatsapp',
					link: this.content.whatsapp
						? `https://api.whatsapp.com/send?phone=${this.content.whatsapp}`
						: null
				},
				{
					icon: 'telegram',
					link: this.content.telegram
						? `https://t.me/${this.content.telegram}`
						: null
				},
				{
					icon: 'instagram',
					link: this.content.instagram
				},
				{
					icon: 'linked-in',
					link: this.content.linkedin
				},
				{
					icon: 'mail',
					link: this.content.email ? `mailto:${this.content.email}` : null
				}
			]
		}
	}
}
</script>

<style lang="scss">
.agent-info {
	padding: 30px 40px;
	border-radius: 4px;
	border: 1px solid var(--color-stroke, #e5e5e5);
	display: flex;
	gap: 20px;
	justify-content: space-between;

	&__top {
		font-size: 18px;
		font-weight: 700;
		line-height: normal;
	}
	&__left {
		display: flex;
		flex-direction: column;
		gap: 20px;
		max-width: 510px;
		width: 100%;
	}
	&__main {
		display: flex;
		gap: 40px;
		align-items: flex-start;
		img {
			border-radius: 4px;
			border: 1px solid var(--opacity-black-10, rgba(0, 0, 0, 0.1));
			overflow: hidden;
			width: 180px;
			object-fit: cover;
			object-position: center;
			aspect-ratio: 1 / 1;
			flex-shrink: 0;
		}
	}
	&__info {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;
	}
	&__info-item {
		display: flex;
		flex-direction: column;
		gap: 3px;
		span {
			font-size: 14px;
			font-weight: 600;
			line-height: normal;
			color: var(--secondary-1, #808080);
		}
		p {
			font-size: 14px;
			font-weight: 400;
			line-height: 18px;
			margin: 0;
		}

		&--langs p {
			text-transform: uppercase;
		}

		&--link a {
			color: #000000;
		}
	}
	&__name {
		font-size: 18px;
		font-weight: 700;
		line-height: normal;
	}
	&__socials {
		display: flex;
		align-items: center;
		gap: 10px;
		padding-bottom: 20px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		align-self: flex-start;
		a {
			background: var(--primary-1, #c99d56);
			border-radius: 8px;
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 30px;
			height: 30px;
			img,
			svg {
				width: 15px;
				height: 15px;
				object-fit: contain;
			}
		}
	}
	&__link {
		display: flex;
		flex-direction: column;
		gap: 3px;
		span {
			font-size: 14px;
			font-weight: 600;
			line-height: normal;
			color: var(--secondary-1, #808080);
		}
		a {
			font-size: 14px;
			line-height: 18px; /* 128.571% */
			color: var(--01, #c99d56);
			text-decoration-line: underline;
		}
	}
	&__right {
		display: flex;
		flex-direction: column;
		gap: 20px;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			left: -95px;
			top: 0;
			width: 1px;
			height: 275px;
			background: rgba(0, 0, 0, 0.1);
		}
		&-info {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}
	}
	&__left-text {
		display: none;
	}
	@media screen and (max-width: 1180px) {
		&__right::after {
			display: none;
		}
	}
	@media screen and (max-width: 912px) {
		flex-direction: column;
		gap: 20px;
		padding: 0;
		border-radius: 0;
		border: none;
	}
	@media screen and (max-width: 479px) {
		&__main {
			gap: 20px;
			img {
				width: 100px;
				height: 100px;
			}
		}
		&__info {
			gap: 5px;
		}
		&__name {
			font-size: 16px;
		}
		&__info-item {
			gap: 0;
			&.text {
				display: none;
			}
			span,
			p {
				font-size: 10px;
			}
		}
		&__left-text {
			display: block;
			font-size: 12px;
			margin: 10px 0;
		}
		&__socials {
			padding-bottom: 10px;
		}
		&__left {
			gap: 10px;
		}
		&__right {
			gap: 10px;
			&-info {
				gap: 5px;
			}
		}
		&__link {
			span,
			a {
				font-size: 12px;
			}
		}
	}
}
</style>
