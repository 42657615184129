<template>
	<div class="offer-page" ref="offerPage">
		<div class="container">
			<h1 class="offer-page__title">{{ offer.title }}</h1>
			<AgentInfo
				v-if="!isLoad"
				class="offer-page__agent-info"
				:content="agent"
			/>
			<or-skeleton v-else class="offer-page__agent-info--skeleton" />

			<div v-if="!isLoad" class="offer-page__units offer-units">
				<div class="offer-units__item">
					<p>Como Residences</p>
					<div class="offer-units__btns">
						<button
							v-for="unit in units"
							class="btn offer-units__toggle"
							:class="{
								'offer-units__toggle--active': unit.id === selectUnitId
							}"
							type="button"
							@click="changeUnit(unit)"
							:key="unit.id"
						>
							{{ unit.beds_count }}BR
						</button>
					</div>
				</div>
			</div>
			<or-skeleton v-else class="offer-page__units--skeleton" />

			<Images v-if="!isLoadUnit" :images="images" class="offer-page__images" />
			<or-skeleton v-else class="offer-page__images--skeleton" />

			<Title
				:is-load="isLoadUnit"
				:content="{
					...project,
					price: currentUnit.price,
					area: currentUnit.area
				}"
				type="unit"
				class="offer-page__block"
			/>

			<Immobilien
				:is-load="isLoadUnit"
				:content="{ ...project, ...unitForImmobilienBlock }"
				:address="address"
				type="offer"
				class="offer-page__block"
			/>

			<Showcase
				v-if="!isLoadUnit"
				:content="project"
				class="offer-page__block offer-page__block--showcase"
			/>
			<or-skeleton v-else class="offer-page__block--skeleton-showcase" />

			<template v-if="!isLoadUnit">
				<SpecialOffers
					v-if="paymentInfo?.length"
					:loanOffersList="paymentInfo"
					class="offer-page__block"
				/>
			</template>
			<or-skeleton v-else class="offer-page__block--skeleton-offers" />

			<template v-if="!isLoadUnit">
				<Tags
					v-if="features?.length"
					:title="$t('ads.features')"
					:content="features"
					class="offer-page__block offer-page__block--tags"
				/>
			</template>
			<or-skeleton v-else class="offer-page__block--skeleton" />

			<template v-if="!isLoadUnit">
				<Tags
					v-if="externalInfrastructure?.length"
					:title="$t('ads.externalInf')"
					:content="externalInfrastructure"
					class="offer-page__block offer-page__block--tags"
				/>
			</template>
			<or-skeleton v-else class="offer-page__block--skeleton" />

			<template v-if="!isLoadUnit">
				<Tags
					v-if="internalInfrastructure?.length"
					:title="$t('ads.iternalInf')"
					:content="internalInfrastructure"
					class="offer-page__block offer-page__block--tags"
				/>
			</template>
			<or-skeleton v-else class="offer-page__block--skeleton" />

			<template v-if="!isLoadUnit">
				<Layout
					v-if="currentUnit.layout?.length"
					class="offer-page__block"
					:layout="currentUnit.layout"
					is-not-tags
				/>
			</template>
			<or-skeleton v-else class="offer-page__block--skeleton-rooms" />
			<!--
			<div v-if="!isLoadUnit" class="offer-page__block offer-page__print print-doc">
				<div class="print-doc__title">
					<h2>{{ $t('web.offer_docs_title') }}</h2>
				</div>
				<or-button @click="printPage" class="print-doc__btn">
					{{ $t('web.offer_docs_print') }}
				</or-button>
			</div>
			<or-skeleton v-else class="offer-page__block--print" /> -->
			<div class="offer-page__block">
				<div class="offer-page__subtitle">Brochures</div>
				<div class="offer-page__brochures">
					<or-button kind="secondary" :height="48">
						Floor plan .PDF <or-icon type="download" />
					</or-button>
					<or-button kind="secondary" :height="48"
						>License .PDF <or-icon type="download" />
					</or-button>
				</div>
			</div>
			<div class="offer-page__block">
				<div class="offer-page__subtitle">{{ $t('web.availability') }}</div>
				<offer-table />
				<offer-table-mobile />
			</div>
			<div class="offer-page__subtitle">{{ $t('web.all_units') }}</div>
			<div class="offer-page__block">
				<div class="offer-page__tables">
					<div class="offer-page__tables-unit">{{ $t('web.unit') }} 1</div>
					<div>
						<div class="offer-page__tables-room">1 {{ $t('web.room') }}</div>
						<offer-table />
						<offer-table-mobile />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AgentInfo from '@/modules/broker/components/AgentInfo.vue'
import { createRequestBroker, createRequest } from '@/api/requestBuilder'
import requestBrokerConfigs from '@/api/requestBrokerConfigs'
import requestConfigs from '@/api/requestConfigs'
import Images from '@/components/SoloPages/Images'
import Title from '@/components/SoloPages/TitleLine'
import Immobilien from '@/components/SoloPages/Immobilien'
import Showcase from '@/components/SoloPages/Showcase'
import KindsHousing from '@/components/SoloPages/KindsHousing'
import Tags from '@/components/SoloPages/Tags'
import Layout from '@/components/SoloPages/Layout'
import SpecialOffers from '@/components/SoloPages/SpecialOffers'
import OfferTable from '@/modules/broker/components/Offer/OfferTable.vue'
import OrButton from '@/components/global/orButton.vue'
import OfferTableMobile from '@/modules/broker/components/Offer/OfferTableMobile.vue'

export default {
	components: {
		OfferTableMobile,
		OrButton,
		OfferTable,
		AgentInfo,
		Images,
		Title,
		Immobilien,
		Showcase,
		KindsHousing,
		Tags,
		Layout,
		SpecialOffers
	},
	data() {
		return {
			isLoad: true,
			isLoadUnit: true,
			selectUnitId: null,
			offerPage: null,
			units: [],
			offer: {},
			project: {},
			agent: {}
		}
	},
	computed: {
		externalInfrastructure() {
			return this.currentUnit.amenities?.filter(
				(el) => el.type === 'exterenal'
			)[0]?.name
		},
		internalInfrastructure() {
			return this.currentUnit.amenities?.filter(
				(el) => el.type === 'internal'
			)[0]?.name
		},
		features() {
			return this.currentUnit.amenities?.filter(
				(el) => el.type === 'features'
			)[0]?.name
		},
		currentUnit() {
			return this.selectUnitId
				? this.units?.find((itm) => itm.id === this.selectUnitId)
				: this.units[0] || {}
		},
		unitForImmobilienBlock() {
			const fields = ['floor', 'beds_count', 'furnished', 'with_balcony']
			const obj = {}
			fields.forEach((itm) => {
				obj[itm] = this.currentUnit[itm]
			})
			return {
				...obj
			}
		},
		paymentInfo() {
			return this.project.loan_offers?.filter(
				(el) => el.title && el.description
			)
		},
		address() {
			return {
				...this.project.location,
				region: { ...this.project.region },
				city: { ...this.project.city }
			}
		},
		images() {
			let images = []
			if (this.project.images) images = images.concat(this.project.images)
			if (this.project.images_interior)
				images = images.concat(this.project.images_interior)
			return images
		}
	},
	methods: {
		async getProject(slug) {
			if (!slug) {
				return
			}

			const response = await createRequest(requestConfigs.GETInfoProject, {
				routerPayload: { slug }
			})

			if (response.status) {
				this.project = { ...response.response }
			}
		},
		async changeUnit(unit) {
			if (this.selectUnitId === unit.id) {
				return
			}

			this.isLoadUnit = true
			this.selectUnitId = unit.id

			await this.getProject(unit.project.slug)

			this.isLoadUnit = false
		},
		printPage() {}
	},
	async beforeCreate() {
		try {
			const response = await createRequestBroker(
				requestBrokerConfigs.GETOffer,
				{
					routerPayload: { offer_id: this.$route.params.offer_id }
				}
			)

			if (!response.data) {
				return
			}

			this.units = [...response.data.units]
			this.offer = { ...response.data }
			this.agent = { ...response.data.broker }

			await this.getProject(this.currentUnit.project.slug)

			this.isLoad = false
			this.isLoadUnit = false
		} catch (err) {
			this.$store.commit('setError', { status: err.errCode || 404 })
		}
	}
}
</script>

<style lang="scss">
.offer-page {
	padding: 50px 0;
	overflow: hidden;

	.container {
		overflow: unset;
	}
	&__brochures {
		display: flex;
		align-items: center;
		gap: 10px;
		flex-wrap: wrap;
		& .button {
			padding: 0 20px;
			min-width: 180px;
			span {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
	}
	&__title {
		font-size: 28px;
		font-weight: 700;
		line-height: 120%;
		margin: 0;
		margin-bottom: 40px;
	}
	&__subtitle {
		margin: 0 0 20px 0;
		font-size: 18px;
		font-weight: 700;
	}
	&__units {
		margin-bottom: 40px;
		&--skeleton {
			height: 42px;
		}
	}
	&__tables {
		display: flex;
		flex-direction: column;
		gap: 20px;
		&-unit {
			font-weight: 700;
			color: var(--03, #000);
		}
		&-room {
			font-size: 14px;
			font-weight: 600;
			color: var(--gray-50, #808080);
			margin-bottom: 5px;
		}
	}
	.offer-units {
		display: flex;
		align-items: center;
		padding: 15px;
		margin-left: -15px;
		gap: 10px;
		overflow-y: auto;
		overflow-x: visible;
		max-width: 100%;
		scrollbar-width: none;

		@media screen and (max-width: 768px) {
			max-width: 105%;
		}

		&::-webkit-scrollbar {
			display: none;
		}
		&__item {
			border-radius: 8px;
			border: 1px solid var(--color-stroke, #e5e5e5);
			background: var(--02, #fff);
			padding: 15px;
			display: flex;
			flex-direction: column;
			gap: 15px;
			p {
				color: #000;
				font-size: 18px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				margin: 0;
			}
		}
		&__toggle {
			border-radius: 8px;
			border: 1px solid var(--secondary-3, #f6f6f6);
			background: var(--background-1, #fff);
			box-shadow: 0px 4px 12px 0px rgba(184, 197, 204, 0.3);
			padding: 10px;
			min-width: 90px;
			font-weight: 700;
			font-size: 16px;
			transition: all 0.2s ease;
			&--active,
			&:active {
				border: 1px solid var(--Secondary-3, #f6f6f6);
				background: var(--01, #c99d56);
			}
			&:hover {
				box-shadow: 0px 4px 6px 0px rgba(184, 197, 204, 0.3);
			}
		}
		&__btns {
			display: flex;
			gap: 10px;
		}
	}

	&__agent-info {
		margin-bottom: 40px;

		&--skeleton {
			height: 475px;
			margin-bottom: 40px;
		}
	}

	@media screen and (max-width: 479px) {
		&__title {
			margin-bottom: 20px;
			font-size: 22px;
		}

		&__units {
			margin-bottom: 20px;
		}

		&__agent-info {
			margin-bottom: 20px;
		}
	}

	&__images {
		margin-top: 40px;
		margin-bottom: 40px;

		@media screen and (max-width: 768px) {
			margin-top: 20px;
			margin-bottom: 20px;
		}

		&--skeleton {
			height: 380px;
			width: 100%;
			margin-top: 40px;
			margin-bottom: 40px;

			@media screen and (max-width: 768px) {
				height: 300px;
				margin-bottom: 20px;
				margin-top: 20px;
			}
		}
	}

	&__block {
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

		&--skeleton {
			width: 100%;
			height: 110px;
			margin-bottom: 30px;
		}

		&--skeleton-showcase {
			width: 100%;
			height: 80px;
			margin-bottom: 30px;
		}

		&--skeleton-rooms {
			width: 100%;
			height: 490px;
			margin-bottom: 30px;
		}

		&--skeleton-offers {
			width: 100%;
			height: 210px;
		}

		&--tags {
			padding-bottom: 20px;
		}

		&--print {
			height: 170px;
		}

		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: none;
		}
	}

	.print-doc {
		padding-top: 10px;

		&__title {
			padding-bottom: 20px;
			margin-bottom: 40px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);

			h2 {
				font-size: 18px;
				font-weight: 700;
			}
		}

		&__btn {
			width: 260px;
			height: 48px;
			padding: 12px 20px;
		}
	}
}
</style>
