<template>
	<div class="offer-table-mobile">
		<div class="offer-table-mobile__body">
			<div class="offer-table-mobile__item" v-for="item in 7" :key="item">
				<div
					class="offer-table-mobile__item-info offer-table-mobile__item-info-number"
				>
					# 16-507
				</div>
				<div
					class="offer-table-mobile__item-list offer-table-mobile__item-list-top"
				>
					<div class="offer-table-mobile__item-info">4 BR</div>
					<div class="offer-table-mobile__item-info">3 297 ft2</div>
				</div>
				<div class="offer-table-mobile__item-list">
					<div
						class="offer-table-mobile__item-info offer-table-mobile__item-info-price"
					>
						<span>2 240 756,7</span> USD
						<p class="offer-table-mobile__subtitle">{{ $t('web.view') }}</p>
					</div>
					<div class="offer-table-mobile__item-info">
						108
						<p class="offer-table-mobile__subtitle">
							{{ $t('web.flatNumber') }}
						</p>
					</div>
					<div class="offer-table-mobile__item-info">
						4
						<p class="offer-table-mobile__subtitle">{{ $t('web.floor') }}</p>
					</div>
					<div class="offer-table-mobile__item-info">Community</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'OfferTableMobile'
}
</script>
<style lang="scss">
.offer-table-mobile {
	&__body {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	&__item {
		padding-bottom: 16px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		&-list {
			display: flex;
			gap: 20px;
			row-gap: 5px;
			flex-wrap: wrap;
		}
		&-list-top {
			margin-bottom: 10px;
		}
	}
	&__item-info {
		font-size: 12px;
		font-weight: 400;
		line-height: normal;
		&-number {
			font-size: 14px;
			font-weight: 700;
			margin-bottom: 5px;
		}
		&-price {
			white-space: nowrap;
			span {
				display: unset;
				color: var(--primary-1, #c99d56);
			}
		}
	}
	&__subtitle {
		padding: 0;
		display: inline-flex;
		margin: 0 0 0 5px;
		font-size: 12px;
		color: var(--gray-50, #808080);
	}
	@media screen and (min-width: 620px) {
		display: none;
	}
}
</style>
